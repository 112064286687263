.user-image {
  display: block;
  background: #fff;
  transform: translate(0, -190px);
  max-height: 400px;
}

.profile-page i {
  display: inline-block;
  font-size: 16px;
  color: #232323;
  text-align: center;
  border: 1px solid #232323;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin:0 5px;
}

.profile-page .icon-block{
  float:left;
  width:100%;
  margin-top:15px;
}

@media (max-width: 600px){
  .user-image {
    display: block;
    background: #fff;
    transform: translate(0, -80px)
  }
}