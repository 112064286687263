.hover:hover{
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  cursor: pointer;
}

section{
  float:left;
  width:100%;
  background: #fff;  /* fallback for old browsers */
  padding:30px 0;
}

.profile-card-3 {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  text-align: center;
  height:368px;
  border:none !important;
}

.profile-card-3 .background-block {
    float: left;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.profile-card-3 .background-block .background {
  width:100%;
  vertical-align: top;
  opacity: 0.9;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
   -webkit-transform: scale(1.8);
  transform: scale(4.8);
}

.profile-card-3 .background-block .background .animate{
  left: 0;
  position: absolute;
  top: 0;
  z-index: 100;
  height: auto;
  -webkit-animation: zoomEffect 35s infinite;
          animation: zoomEffect 35s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}


.profile-card-3 .card-content {
  width: 100%;
  padding: 15px 25px;
  color:#232323;
  float:left;
  background:#efefef;
  height:50%;
  border-radius:0 0 5px 5px;
  position: relative;
  z-index: 100;
}

.profile-card-3 .card-content::before {
    content: '';
    background: #efefef;
    width: 120%;
    height: 100%;
    left: 11px;
    bottom: 51px;
    position: absolute;
    z-index: -1;
    transform: rotate(-13deg);
}

.profile-card-3 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  max-width: 100px;
  opacity: 1;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  z-index:1000;
  background: #fff;
}

.profile-card-3 .profile-online {
  position: absolute;
  border-radius: 0px;
  bottom: 50%;
  left: 50%;
  max-width: 100px;
  opacity: 1;
  transform: translate(-50%, 0%);
  z-index: 1000;
}

.profile-card-3 h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size:25px;
}

.profile-card-3 h2 small {
  display: block;
  font-size: 15px;
  margin-top:10px;
}

.profile-card-3 i {
  display: inline-block;
  font-size: 16px;
  color: #232323;
  text-align: center;
  border: 1px solid #232323;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin:0 5px;
}

.profile-card-3 .icon-block{
  float:left;
  width:100%;
  margin-top:15px;
}

.profile-card-3 i:hover {
  background-color:#002d73;
  color:#fff;
  text-decoration:none;
}

@-webkit-keyframes zoomEffect {
  0% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
  100% {
    -webkit-transform: scale(1.2) translateX(-360px) translateY(-80px);
            transform: scale(1.2) translateX(-360px) translateY(-80px);
  }
}

@keyframes zoomEffect {
  0% {
    -webkit-transform: scale(1) translateX(0) translateY(0);
            transform: scale(1) translateX(0) translateY(0);
  }
  100% {
    -webkit-transform: scale(1.2) translateX(-360px) translateY(-80px);
            transform: scale(1.2) translateX(-360px) translateY(-80px);
  }
}