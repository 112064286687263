.title {
  font-size: 1.4em;
  font-weight: 300;
}

.bar-group .name-label {
  text-anchor: end;
  font-weight: 300;
  font-size: 1em;
  fill: #333;
}

.bar-group .value-label {
  text-anchor: end;
  fill: #fff;
  font-weight: 900;
  font-size: 0.7em;
}

.chart-container .bar-group {
  transition: opacity 0.3s;
}

.chart-container:hover .bar-group {
  opacity: 0.3;
}

.chart-container .bar-group:hover {
  opacity: 1;
}

.chart-container .bar-group:hover .name-label {
  font-weight: 400;
}

.chart-container .bar-group:hover .value-label {
  font-size: 0.8em;
}

.pie-chart .value-label {
  fill: #fff;
  font-weight: 900;
  font-size: 0.7em;
}

.chart-container .pie-chart {
  transition: opacity 0.3s;
}

.chart-container:hover .pie-chart {
  opacity: 0.3;
}

.chart-container .pie-chart:hover {
  opacity: 1;
}

.chart-container .pie-chart:hover .value-label {
  font-size: 0.8em;
}

.Charts {
	margin: 0 auto;
	display: flex;
	align-items: flex-end;
	// height: 25em;
	
	&.horizontal {
		display: block;
	}
	
	&.horizontal &--serie {
		display: block;
		margin: 0 0 30px 0;
		border: 0;
		
		label {
			position: relative;
			top: auto;
			right: auto;
			left: 0;
			bottom: auto;
			padding: 0 0 10px 0;
		}
	}
	
	&--serie {
		height: 100%;
		margin: 0 30px 0 0;
		display: inline-block;
		flex: 1;
		display: flex;
		align-items: flex-end;
		transform-origin: 0 100%;
		animation: slideUp .6s;
		position: relative;
		border-bottom: 1px solid #c2c2c2;
		
		&.stacked {
			display: block;
		}
		
		label {			
			position: absolute;
			left: 0;
			right: 0;
			bottom: -20px;
			font-family: Helvetica, sans-serif;
			font-size: 10px;
			text-align: center;
			color: #808080;
		}
	}
	
	&.horizontal &--item {
		display: block;
		border-radius: 0 2px 2px 0;
		margin: 0 0 5px 0;
		height: 1em;
		
		b {
			position: absolute;
			right: -20px;
			top: .3em;
		}
	}
	
	&--item {
		background-color: #002d73;
		display: inline-block;
		margin: 0 5px 0 0;
		flex: 1;
		transition: height 1s ease-out, width 1s ease-out;
		position: relative;
		text-align: center;
		border-radius: 2px 2px 0 0;
		
		&.layered {
			position: absolute;
			left: 5%;
			right: 5%;
			bottom: 0;
			margin: 0;
			
			b {
				position: absolute;
				right: 0;
			}
		}
		
		&.stacked {
			position: relative;
			display: block;
			border-radius: 0;
		}
		
		b {
			position: relative;
			font-family: Helvetica, sans-serif;
			font-size: 10px;
			top: -20px;
			color: #002d73;
		}
	}
}

.Legend {
	&--color {
		display: inline-block;
		vertical-align: middle;
		border-radius: 2px;
		width: 16px;
		height: 16px;
	}
	
	&--label {
		display: inline-block;
		vertical-align: middle;
		font-family: Helvetica, sans-serif;
		font-size: 12px;
		margin: 0 0 0 5px;
	}
}

@keyframes slideUp {
	from { transform: scaleY(0); }
	to { transform: scaleY(1); }
}