.table-title {
  padding-bottom: 15px;
  color: #111;
  margin: -20px -25px 10px;
  margin-top: 30px;
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.table-title .btn {
  float: right;
  margin-left: 10px;
}
.table-title .btn:hover, .table-title .btn:focus {
  color: #566787;
  background: #f2f2f2;
}
.table-title .btn i {
  float: left;
  font-size: 21px;
  margin-right: 5px;
}
.table-title .btn span {
  float: left;
  margin-top: 2px;
}

.team {
  padding-top: 20px;
  padding-bottom: 20px;
}

.edit-btn {
  float: right;
  margin-right: 10px;
}