$background-color: #999;
$search-bg-color: #fff;
$icon-color: #002d73;
$transition: all .5s ease;
* {
  box-sizing: border-box;
}

.search {
  width: 40px;
  height: 40px;
  margin: 0 auto 0;
  background-color: $search-bg-color;
  position: relative;
  overflow: hidden;
  transition: $transition;
  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: relative;
    background-color: $icon-color;
    transition: $transition;
  }
  &.open {
    width: 100%;
    &:before {
      position: absolute;
    }
  }
}

.search-box {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  background: transparent;
  padding: 20px 100px 20px 45px;
  &:focus {
    outline: none;
  }
}

.search-button {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  cursor: pointer;
}

.search-icon {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  border: 3px solid $icon-color;
  display: block;
  position: relative;
  margin-left: 4px;
  transition: $transition;
  &:before {
    content: '';
    width: 3px;
    height: 10px;
    position: absolute;
    right: -4px;
    top: 13px;
    display: block;
    background-color: $icon-color;
    transform: rotate(-45deg);
    transition: $transition;
  }
  &:after {
    content: '';
    width: 3px;
    height: 15px;
    position: absolute;
    right: -12px;
    top: 40px;
    display: block;
    background-color: $icon-color;
    transform: rotate(-45deg);
    transition: $transition;
  }
  .open & {
    margin: 0;
    width: 30px;
    height: 30px;
    border-radius: 60px;
    &:before {
      transform: rotate(52deg);
      right: 9px;
      top: 10px;
      height: 10px;
    }
    &:after {
      transform: rotate(-230deg);
      right: 9px;
      top: 5px;
      height: 10px;
    }
  }
}