.commentContent {
  z-index: 10001;
  height: 100vh;
  width: 100vh !important;
  background: aliceblue;
}

.commentCover {
  position:fixed;
  width:100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:10000;
}