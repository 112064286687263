$transition: all .5s ease;

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  transition: $transition;
}
.sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  display: block;
  transition: $transition;
  &.closed {
    display: none !important;
  }
}
.sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
