.avatar-small {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: left;
  position: relative;
}

.avatar-status-overlay {
  position: absolute;
  top: 12px;
  left: 18px;
  padding: 2px;
}