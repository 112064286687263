* {
  font-family: Maersk Headline Light, sans-serif;  
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./Roboto-Light.eot"); /* IE9 Compat Modes */
  src: url("./Roboto-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./Roboto-Light.otf") format("opentype"), /* Open Type Font */
    url("./Roboto-Light.svg") format("svg"), /* Legacy iOS */
    url("./Roboto-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./Roboto-Light.woff") format("woff"), /* Modern Browsers */
    url("./Roboto-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Maersk Headline Light';
  src: url('./MaerskHeadline-Light.eot');
  src: url('./MaerskHeadline-Light.eot?#iefix') format('embedded-opentype'),
      url('./MaerskHeadline-Light.woff2') format('woff2'),
      url('./MaerskHeadline-Light.woff') format('woff'),
      url('./MaerskHeadline-Light.ttf') format('truetype'),
      url('./MaerskHeadline-Light.svg#MaerskHeadline-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maersk Headline';
  src: url('./MaerskHeadline-Regular.eot');
  src: url('./MaerskHeadline-Regular.eot?#iefix') format('embedded-opentype'),
      url('./MaerskHeadline-Regular.woff2') format('woff2'),
      url('./MaerskHeadline-Regular.woff') format('woff'),
      url('./MaerskHeadline-Regular.ttf') format('truetype'),
      url('./MaerskHeadline-Regular.svg#MaerskHeadline-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maersk Text';
  src: url('./MaerskText-Regular.eot');
  src: url('./MaerskText-Regular.eot?#iefix') format('embedded-opentype'),
      url('./MaerskText-Regular.woff2') format('woff2'),
      url('./MaerskText-Regular.woff') format('woff'),
      url('./MaerskText-Regular.ttf') format('truetype'),
      url('./MaerskText-Regular.svg#MaerskText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
